<template>
  <popup-dialog @close="close" v-if="visible">
    <div class="login-chinese">
      <div class="login-chinese-logo">
        <img src="~@/assets/chinese_logo.png" alt="CF40 RESEARCH" />
      </div>
      <div class="login-chinese-content">
        <div class="login-chinese-content-tab">
          <div :class="{ 'login-chinese-content-tab-cell': true, 'active': selectTab == 'account' }" @click="tabChange('account')">账户</div>
          <div :class="{ 'login-chinese-content-tab-cell': true, 'active': selectTab == 'scan' }" @click="tabChange('scan')">扫码</div>
        </div>
        <div class="login-chinese-content-form" v-if="selectTab == 'account'">
          <el-form ref="subForm" :model="subForm" size="default" :rules="rules" label-width="80px">
            <el-form-item label="账户" prop="username" v-if="type == 'login'">
              <el-input v-model="subForm.username" placeholder="请输入登陆账户" @keyup.native.enter="operate" clearable></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email" v-if="type == 'register' || type == 'forgot'">
              <el-input v-model="subForm.email" placeholder="请输入邮箱地址" @keyup.native.enter="operate" clearable></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="subForm.password" type="password" placeholder="请输入密码" @keyup.native.enter="operate" clearable></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass" v-if="type == 'register' || type == 'forgot'">
              <el-input v-model="subForm.checkPass" type="password" placeholder="请再次输入密码" @keyup.native.enter="operate" clearable></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-if="type == 'register' || type == 'forgot'">
              <el-input v-model="subForm.code" placeholder="请输入邮箱验证码" @keyup.native.enter="operate" clearable>
                <el-button slot="append" @click="getVeriCode">获取验证码</el-button>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="submit" :loading="loading" @click="operate">
                <span v-if="type == 'login'">登录</span>
                <span v-else-if="type == 'register'">注册</span>
                <span v-else-if="type == 'forgot'">重置密码</span>
              </el-button>
            </el-form-item>
            <template v-if="type == 'login'">
              <el-form-item>
                <div class="flex-row">
                  <div class="forgot-pass" @click="typeChange('forgot')">
                    忘记密码，点击找回?
                  </div>
                  <div class="register" @click="typeChange('register')">
                    注册
                  </div>
                </div>
              </el-form-item>
            </template>
            <template v-else-if="type == 'register'">
              <el-form-item>
                <div class="flex-row">
                  <div class="forgot-pass no-link">
                    已经有一个帐户?
                  </div>
                  <div class="register" @click="typeChange('login')">
                    登录
                  </div>
                </div>
              </el-form-item>
            </template>
            <template v-else-if="type == 'forgot'">
              <el-form-item>
                <div class="flex-row">
                  <div class="forgot-pass"></div>
                  <div class="register" @click="typeChange('login')">
                    登录
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div class="login-chinese-content-qrcode" v-else>
          <div class="login-chinese-content-qrcode-icon" v-loading="loading">
            <el-image :src="qrcode.qr_code" lazy style="width: 300px; height: 300px"></el-image>
          </div>
        </div>
      </div>
    </div>
  </popup-dialog>
</template>

<script>
import PopupDialog from "../popupDialog";
import { appletQR, testLogin, loginSystem, registerSystem, sendRegisterCode, forgotPassword, sendForgotCode } from "@/api";
export default {
  name: "LoginChinese",
  components: {
    PopupDialog
  },
  data() {
    return {
      visible: false,
      loading: false,
      type: "login",
      selectTab: "account",
      subForm: {},
      rules: {
        username: [
          { required: true, message: "请输入登录账号信息", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 16, message: "密码的长度在6到16位之间", trigger: "blur" }
        ],
        checkPass: [
          { validator: this.validatePassword, trigger: 'blur' }
        ],
        code: [
          { required: true, message: "请输入邮箱验证码", trigger: "blur" }
        ]
      },
      qrcode: {
        // 二位码图片
        qr_code: "",
        // 验证token
        validateToken: "",
        // 二维码定时器
        qrTimer: null,
        // 检测登录定时器
        testTimer: null
      },
      // 倒计时描述
      countdown: 0,
      // 验证码计时器
      codeTimer: null,
    };
  },
  methods: {
    // 打开窗口
    open() {
      this.type = "login";
      this.subForm = {
        username: "",
        email: "",
        password: "",
        checkPass: "",
        code: "",
        token: ""
      };
      this.qrcode = {
        qr_code: "",
        validateToken: "",
        qrTimer: null,
        testTimer: null
      }
      this.visible = true;
    },
    // 关闭窗口
    close () {
      clearInterval(this.qrcode.testTimer);
      clearInterval(this.qrcode.qrTimer);
      this.visible = false;
    },
    // 验证两次密码是否一致
    validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.subForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    // 类型切换
    typeChange(e) {
      this.type = e;
      this.$refs.subForm.resetFields();
    },
    // tab切换
    tabChange(e) {
      if (e == 'scan') {
        this.refreshQr();
      } else {
        clearInterval(this.qrcode.testTimer);
        clearInterval(this.qrcode.qrTimer);
      }
      this.selectTab = e;
    },
    // 二维码刷新
    refreshQr() {
      let _This = this;
      this.getQrCode();
      this.qrcode.qrTimer = setInterval(function() {
        _This.getQrCode();
      }, 300000);
    },
    // 获取二维码
    async getQrCode() {
      let _This = this;
      this.loading = true;
      let res = await appletQR();
      if (res && res.code == 200) {
        this.qrcode.qr_code = `data:image/png;base64,${res.data.data}`;
        this.qrcode.validateToken = res.data.stoken;
        this.qrcode.testTimer = setInterval(function() {
          _This.detectLogin();
        }, 1000);
      }
      this.loading = false;
    },
    // 检测登录
    async detectLogin() {
      let res = await testLogin({ stoken: this.qrcode.validateToken });
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
        }
      } else {
        clearInterval(this.qrcode.testTimer);
        clearInterval(this.qrcode.qrTimer);
        this.refreshQr();
      }
    },

    // 登录系统操作
    async loginHandle() {
      this.loading = true;
      let param = {
        username: this.subForm.username,
        password: this.subForm.password,
      };
      let res = await loginSystem(param);
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
          this.$message.success("successful");
        }
      }
      this.loading = false;
    },
    // 注册系统操作
    async registerHandle() {
      this.loading = true;
      let param = {
        email: this.subForm.email,
        password: this.subForm.password,
        code: this.subForm.code,
      };
      let res = await registerSystem(param);
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
          this.$message.success("successful");
        }
      }
      this.loading = false;
    },
    // 重置密码操作
    async forgotHandle() {
      this.loading = true;
      let param = {
        password: this.subForm.password,
        code: this.subForm.code,
        token: this.subForm.token
      };
      let res = await forgotPassword(param);
      if (res && res.code == 200) {
        this.type = "login";
        this.$message.success(res.msg ? res.msg : "Reset password successful");
      }
      this.loading = false;
    },
    // 发送注册验证码
    async registerCode() {
      this.loading = true;
      let param = {
        email: this.subForm.email
      };
      let res = await sendRegisterCode(param);
      if (res && res.code == 200) {
        this.$message.success(res.msg ? res.msg : 'verification code sent successfully');
      }
      this.loading = false;
    },
    // 发送找回密码验证码
    async forgotCode() {
      this.loading = true;
      let param = {
        email: this.subForm.email
      };
      let res = await sendForgotCode(param);
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          this.subForm.token = res.data.token;
          this.$message.success(res.msg ? res.msg : 'verification code sent successfully');
        }
      }
      this.loading = false;
    },
    // 获取验证码
    getVeriCode() {
      let _This = this;
      this.$refs.subForm.validateField('email', (valid) => {
        if (valid == '') {
          if (!_This.codeTimer) {
            _This.countdown = 60;
            if (_This.type == "register") {
              this.registerCode();
            } else if (_This.type == "forgot") {
              this.forgotCode();
            }
            _This.codeTimer = setInterval(() => {
              _This.countdown--;
              if (_This.countdown <= 0) {
                clearInterval(_This.codeTimer);
                _This.codeTimer = null;
              }
            }, 1000);
          }
        }
      });
    },
    // 执行操作
    operate() {
      this.$refs.subForm.validate((valid) => {
        if (valid) {
          if (this.type == 'login') {
            this.loginHandle();
          } else if (this.type == 'register') {
            this.registerHandle();
          } else if (this.type == 'forgot') {
            this.forgotHandle();
          }
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.login-chinese {
  display: flex;
  align-items: center;
  &-logo {
    max-width: 280px;
    margin-left: 50px;
    margin-right: 30px;
  }
  &-content {
    flex: 1;
    margin-left: 30px;
    &-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      &-cell {
        position: relative;
        cursor: pointer;
        user-select: none;
        font-size: 18px;
        margin-left: 50px;
        &:hover, &.active {
          font-family: HarmonyOS_Bold;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 2px;
            background: #ed6f33;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &-form {
      margin-right: 30px;
      margin-top: 30px;
      margin-bottom: 50px;
      .submit {
        width: 100%;
        padding: 10px 30px;
        font-size: 16px;
        font-family: HarmonyOS_Bold;
        color: #ffffff;
        background-color: #ed6f33;
        &:hover {
          color: #ffffff;
          border-color: #fa996b;
          background-color: #fa996b;
        }
        &:active {
          border-color: #ed6f33;
          background-color: #ed6f33;
        }
      }
      .flex-row {
        display: flex;
        justify-content: space-between;
        .forgot-pass, .register {
          cursor: pointer;
          user-select: none;
          font-family: HarmonyOS_Bold;
          &:hover {
            color: #ed6f33;
          }
          &.no-link {
            cursor: auto;
            &:hover {
              color: inherit;
            }
          }
        }
      }
      ::v-deep {
        .el-form-item__label {
          color: inherit;
          font-size: 16px;
        }
        .el-input-group__append {
          color: #ffffff;
          border-color: #ed6f33;
          background-color: #ed6f33;
          &:hover {
            border-color: #fa996b;
            background-color: #fa996b;
          }
          &:active {
            border-color: #ed6f33;
            background-color: #ed6f33;
          }
        }
      }
    }
    &-qrcode {
      margin: 50px 0 20px 0;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .login-chinese {

  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .login-chinese {
    flex-direction: column;
    &-logo {
      max-width: 230px;
      margin: 15px 0 0 0;
    }
    &-content {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
</style>
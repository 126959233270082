<template>
  <div :class="{ 'web-footer': true, 'en-font': language == 'en', 'cn-font': language == 'cn' }">
    <div class="web-footer-box">
      <div class="web-footer-box-card">
        <a href="javascript:void(0)" class="web-footer-box-card-icon">
          <img src="~@/assets/cf40.png" />
        </a>
        <span class="web-footer-box-card-text">
            Copyright © CHINA FINANCE 40 FORUM 2009-2024
          </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebFooter",
  props: {
    language: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      data: {}
    };
  },
  created() {}
}
</script>
<style lang="scss" scoped>
.en-font {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.web-footer {
  padding: 10px 0;
  background-color: #231815;
  &-box {
    width: 1200px;
    margin: 0 auto;
    &-card {
      display: flex;
      align-items: center;
      &-icon {
        width: 40px;
        line-height: 0;
        display: inline-block;
      }
      &-text {
        font-size: 12px;
        margin-left: 30px;
        color: #d3d1d0;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-footer {
    &-box {
      width: calc(100% - 60px);
      padding: 0 30px;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-footer {
    &-box {
      width: calc(100% - 60px);
      padding: 0 30px;
    }
  }
}
</style>